import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../sections/contactForm"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <article>
      <section id="not-found" className="container py-5">
        <h1 className="row justify-content-center dubbelbit">NOT FOUND</h1>
        <div className="row justify-content-center dubbelbit">
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
        <hr className="featurette-divider"></hr>
      </section>

      <ContactForm />
    </article>
  </Layout>
)

export default NotFoundPage
