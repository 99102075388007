import React from "react"

const ContactForm = () => (
  <>
    <section id="contact-form" className="contact-form">
      <div className="container">
        <h2 className="row justify-content-center dubbelbit">
          Connect with us
        </h2>
        <p>
          If you have any question regarding our services, products or just want
          to get in touch with us, please feel free to contact us through this
          form.
        </p>

        <form
          action=" https://formsubmit.co/ea22ae86f17785c456cd88e9b3e690a5"
          method="POST"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="initial">Initial</label>
                <select
                  className="form-control form-control-sm"
                  id="initial"
                  name="initial"
                >
                  <option></option>
                  <option>Mr.</option>
                  <option>Mrs.</option>
                </select>
              </div>

              <div className="form-group required">
                <label htmlFor="name">Full name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control form-control-sm"
                  placeholder="Full name"
                  required
                />
              </div>
              <div className="form-group required">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control form-control-sm"
                  placeholder="Enter email"
                  required
                />
              </div>

              <b>How can we help?</b>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="c1"
                  name="software development projects"
                />
                <label className="form-check-label" htmlFor="c1">
                  Software development projects
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="c3"
                  name="technocal support for small and medium enterprises"
                />
                <label className="form-check-label" htmlFor="c3">
                  Technical support for Small &amp; Medium Enterprises
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="c3"
                  name="technical expertise"
                />
                <label className="form-check-label" htmlFor="c2">
                  Technical Expertise
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="c3"
                  name="other"
                />
                <label className="form-check-label" htmlFor="c3">
                  Other
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="query">Query</label>
                <textarea
                  id="query"
                  name="query"
                  className="form-control"
                  rows="10"
                  placeholder="Please specify your question here."
                ></textarea>
              </div>
            </div>
          </div>

          <input type="text" name="_honey" style={{ display: `none` }}></input>
          <input type="hidden" name="_replyto"></input>
          <input type="hidden" name="_template" value="table"></input>
          <input
            type="hidden"
            name="_next"
            value="https://www.dubbelbit.nl/"
          ></input>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </section>
  </>
)

export default ContactForm
